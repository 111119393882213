<template>
  <div>
    <h1>Nieuwe groep toevoegen</h1>
    <GroupForm buttonValue="Toevoegen" @save-group="saveGroup" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMutation } from 'villus';
import { useRouter } from 'vue-router';
import GroupForm from '../components/GroupForm.vue';

export default defineComponent({
  components: { GroupForm },
  setup() {
    const router = useRouter();

    const CreateGroup = `
      mutation CreateGroup($name: String!) {
        CreateGroup(name: $name)  {
          id
        }
      }
    `;

    const { execute } = useMutation(CreateGroup);

    const saveGroup = (group: Group) => {
      execute(group)
        .then(() => {
          router.push({ name: 'Groups', params: { message: 'Groep aangemaakt' } });
        });
    };

    return { saveGroup };
  },
});
</script>
