<template>
  <form @submit.prevent="submit">
    <p>
      <label>
        Groepsnaam <br />
        <input type="text" v-model="name" required />
      </label>
    </p>
    <p>
      <button>{{ buttonValue }}</button>
    </p>
  </form>
</template>

<script lang="ts">
import {
  defineComponent, toRefs, reactive, PropType,
} from 'vue';

export default defineComponent({
  props: {
    group: Object as PropType<Group>,
    buttonValue: String,
  },

  setup(props, { emit }) {
    const newGroup = {
      name: '',
    };

    const variables = reactive(props.group ?? newGroup);
    const submit = () => emit('save-group', variables);

    return { ...toRefs(variables), submit };
  },
});
</script>
